input,
textarea {
  background-color: transparent;
  border: 1px solid #f2cb05;
  padding: 1rem;
  box-shadow: none;
}

*:focus {
    outline: none;
}

button{
    background-color: transparent;
    border: 2px solid #f2cb05;
    color: white;
}

.achievement_list>div>*{
	transition: ease-in-out;
	transition-duration: 400ms;
}
.year_color{
	color: #f2cb05;
	transform: scale(1.25);
}

.achievement_text{
	transform: scale(1.25);
}

.emailcheck {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.emailcheck input {
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 2px solid white;
}
/* 
.emailcheck:hover input ~ .checkbox {
  background-color: #f2cb05;
} */

.emailcheck input:checked ~ .checkbox {
  background-color: transparent;
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.emailcheck input:checked ~ .checkbox:after {
  display: block;
}

.emailcheck .checkbox:after {
  width: 15px;
  height: 15px;
  margin: 3px;
  background-color: #f2cb05;
  align-items: center;
}


.dotstyle ul {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	list-style: none;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dotstyle li {
	position: relative;
	display: block;
	float: left;
	margin: 0 16px;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.dotstyle li a {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: none;
	border-radius: 50%;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.3);
	text-indent: -999em; /* make the text accessible to screen readers */
	cursor: pointer;
	position: absolute;
}

.dotstyle li a:focus {
	outline: none;
}

/* Circle grow */

.dotstyle-circlegrow li a {
	overflow: hidden;
	background-color: transparent;
	box-shadow: inset 0 0 0 2px white;
	-webkit-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}

.dotstyle-circlegrow li a::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	border-radius: 50%;
	background-color: #fff;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: transform 0.3s ease;
}

.dotstyle-circlegrow li a:hover,
.dotstyle-circlegrow li a:focus {
	opacity: 0.7;
}

.dotstyle-circlegrow li.current a::after {
	-webkit-transform: scale(1);
	transform: scale(1);
}